import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Card, CardMedia,CardActions,Button, Link as MdLink } from "@material-ui/core"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"

import ListItemText from "@material-ui/core/ListItemText"
import {
  MdHome,
  MdAssignment,
  MdLocationOn,
  MdCreate,
  MdMail,  
} from "react-icons/md"
import {FaRegListAlt} from "react-icons/fa"

import {Link} from 'gatsby'
import coloquiogif from '../images/gifcoloquio.gif'
import coloquioafiche from '../images/afiche.pdf'
//import Img from 'gatsby-image';


const useStyles = makeStyles(theme => ({
  aficheBox: {
  
  },
  afiche: {
    maxWidth: "100%",
    height: "auto",
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    marginBottom: 0,
  },
  
  divider: {
    backgroundColor: "rgba(255,255,255,0.2)",
    marginTop: "5px",
  },
  listItem:{
    color:'#f7b032',    
    '&:hover':{
      
      backgroundColor:'rgba(0, 0, 0, 0.8);'
    }
  },
  iconList: {
    color: "#f7b032",

  },
  icon:{
    width:'1.7em',
    height:'1.7em',
  },

  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: 'unset',
    color:'unset',

  }
}))

const Menu = props => {
  const classes = useStyles()    
  
 /* const data = useStaticQuery(graphql`
  query {
    afiche: file(relativePath: { eq: "gifcoloquio.gif" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`);*/
  

  return (
    <div className={classes.toolbar}>
      <Card square>
        <CardMedia className={classes.aficheBox}>
          <img
            style={{ width: "100%" }}
            src={coloquiogif}
            alt="Afiche del 7mo Coloquio Uruguayo de Matemática"
          />
          {/*<Img fixed={data.afiche.childImageSharp.fixed} alt="..." className={classes.afiche} />*/}
        </CardMedia>
        <CardActions style={{ padding: "0px 5px 5px 5px" }}>
          <Button
            fullWidth
            href={coloquioafiche}
            color="primary"
            variant="contained"
          >
            Descargar Afiche
          </Button>
        </CardActions>
      </Card>

      <Divider className={classes.divider} />
      <List>
        {[
          {
            text: "Inicio",
            icon: <MdHome className={classes.icon} />,
            link: "/",
          },
          {
            text: "Programa",
            icon: <MdAssignment className={classes.icon} />,
            link: "/programa",
          },
          {
            text: "Mapa",
            icon: <MdLocationOn className={classes.icon} />,
            link: "/mapa",
          },
          {
            text: "Inscripción",
            icon: <MdCreate className={classes.icon} />,
            link: "/inscripcion",
          },
          {
            text: "Lista de Inscriptos",
            icon: <FaRegListAlt className={classes.icon} />,
            link: "/inscriptos",
          },
          {
            text: "Contacto",
            icon: <MdMail className={classes.icon} />,
            link: "/contacto",
          },
        ].map((item, index) => {
          let comp = (
            <ListItem className={classes.listItem} button key={index} >
              <ListItemIcon className={classes.iconList}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          )
          let link = item.external ? (
            <MdLink
              underline="none"
              rel="noopener"
              style={{ display: "block", color: "white" }}
              href={item.link}
            >
              {comp}
            </MdLink>
          ) : (
            <Link key={index} className={classes.link} to={item.link}>
              {comp}
            </Link>
          )

          return link
        })}
      </List>
      <Divider className={classes.divider} />
    </div>
  )
}
export default Menu;
