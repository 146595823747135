import React from "react"
import { makeStyles} from "@material-ui/core/styles"
import {
  Divider,
  Container,
  Typography, 
  Grid,
} from "@material-ui/core"
import MuiLink from "@material-ui/core/Link"
import {useStaticQuery} from 'gatsby'
import Img from 'gatsby-image';

const drawerWidth=220

const useStyles = makeStyles(theme => ({
    footer:{
        background:'#222629',
        color:'white',
        textAlign:'center',       
        [theme.breakpoints.up("md")]: {
          marginLeft: drawerWidth,
        },
      }
    
}))

const Footer = (props)=>{
  const classes = useStyles()
  const query = useStaticQuery(graphql`
    query {
      fing: file(relativePath: { eq: "logos/fing.png" }){
        childImageSharp{
          fixed(width:50){
            ...GatsbyImageSharpFixed,
                originalName
          }
        }
      }
      pedeciba: file(relativePath: { eq: "logos/pedeciba.png" }){
        childImageSharp{
          fixed(height:110){
            ...GatsbyImageSharpFixed,
                originalName
          }
        }
      }
      udelar: file(relativePath: { eq: "logos/udelar.png" }){
        childImageSharp{
          fixed(height:50){
            ...GatsbyImageSharpFixed,
                originalName
          }
        }
      }
      imerl: file(relativePath: { eq: "logos/imerl.png" }){
        childImageSharp{
          fixed(height:75){
            ...GatsbyImageSharpFixed,
                originalName
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "logos"}}) {
        edges {
          node {
            childImageSharp {
              fixed(height:60) {
                ...GatsbyImageSharpFixed,
                originalName
              }
            }
          }
        }
      }}`);      
   
    const logos = query.allFile.edges.reduce((index,item)=>(     
     {...index, 
      [item.node.childImageSharp.fixed.originalName.split('.').slice(0, -1).join('.')]:
     item.node.childImageSharp.fixed}

    ),{})
    logos.pedeciba = {...query.pedeciba.childImageSharp.fixed, link:"http://pedeciba.edu.uy" };
    logos.cmat = {...logos.cmat,link:"http://www.cmat.edu.uy"}
    logos.dmel={...logos.dmel,link:"http://dmel.multisitio.interior.edu.uy"}
    logos.imerl = {...query.imerl.childImageSharp.fixed, link:"https://www.fing.edu.uy/imerl"};
    logos.fadu = {...logos.fadu, link:"https://www.fadu.edu.uy"}  
    logos.fcien = {...logos.fcien, link:"https://www.fcien.edu.uy"}  
    logos.cure = {...logos.cure, link:"http://cure.edu.uy"}  
    logos.fing = {...query.fing.childImageSharp.fixed, link:"https://www.fing.edu.uy"};    
    logos.udelar = {...query.udelar.childImageSharp.fixed, link:"http://www.universidad.edu.uy"};
    

    const logosOrder =['pedeciba','cmat','dmel','imerl',
    'fadu', 'fcien','cure','fing','udelar'].map((val)=>{
      return logos[val]
    })
    
    
    
  return(
      <footer className={classes.footer}>
          <Divider />
          <Container maxWidth="lg">
            <Grid container alignItems="center" justify={"space-between"}>
              <Grid item xs={12}>
              <Typography variant="overline" gutterBottom>Organizan y financian:</Typography>
              </Grid>
              <Grid item alignItems="center" alignContent="center" justify="space-around" container xs={12}>
              {
                logosOrder.map((item,index)=>{
                  return(
                    <a key={index} href={item.link} target="_blank" rel="noreferrer noopener">
                      <Img fixed={item} />
                    </a>
                  )
                })
              }
              
              </Grid>
            </Grid>
          </Container>
          <Divider style={{color:'white'}}/>
          <Container maxWidth="sm">
            <Typography variant="overline" gutterBottom>Creado por: <MuiLink href="http://www.cmat.edu.uy">El Centro de Matemática</MuiLink></Typography>
            <Typography variant="subtitle2">Germán Correa - germanc@cmat.edu.uy</Typography>
          </Container>
      </footer>
  )
}
  
  export default Footer;