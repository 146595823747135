import React from "react"
import Menu from "./SideBar"
import Header from "./Header"
import Footer from "./Footer"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Drawer,  
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  useScrollTrigger,
  Slide,
  CssBaseline,
 
} from "@material-ui/core"


import { MdMenu, MdChat } from "react-icons/md"



const drawerWidth = 220

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection:'column',
    minHeight:'100vh',
  },

  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    backgroundColor: "white",
    //backgroundColor: "#bdc3c7" /* fallback for old browsers */,
    //background:
    //  "linear-gradient(to bottom, rgba(255,255,255,0.7),rgba(98,130,129,0.9))",
    //boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.85)",
    
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: "#222629",
    color: "white",
    border:'unset',
  },
  content: {
    flexGrow: 1,
    background: "#eee",
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    },
  },
  
}))

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export default ({ children, container }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  return (
    <CssBaseline>
    <>
      <div className={classes.root}>
        <HideOnScroll>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="default"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MdMenu size="1.3em" />
              </IconButton>
              <Header />
              <IconButton aria-label="show 4 new mails" color="default">
                <Badge badgeContent={1} color="error">
                  <MdChat size="1.3em" />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <nav className={classes.drawer}>
          <Hidden smDown implementation="css">
            <Drawer              
              variant="permanent"
              anchor="left"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Menu />
            </Drawer>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Drawer
              
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
              ModalProps={{ keepMounted: true }}
            >
              <Menu />
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          {children}          
        </main>
        <Footer />
       
      </div>
    </>
    </CssBaseline>
  )
}
