import React from 'react';
import Container from '@material-ui/core/Container'
//import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import {useStaticQuery} from 'gatsby'
import Img from 'gatsby-image';
//import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
titleContainer:{
   
},
headerTitle: {
    textShadow: "#ccc 1px 1px 10px",
    color: "black",
    fontWeight: "500",   
    fontSize:'2em', 
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  headerImg:{
   height:'64px',
   [theme.breakpoints.down('xs')]:{
      height:'60px',
    }
  },
  romanNumber: {
    fontFamily: "DejaVu Serif Condensed",
    fontSize: "2em",
    float: "left",
    paddingRight: "5px",
    color:'#f8b135',
  },
  when: {
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
}));


export default function Header() {
    const data = useStaticQuery(graphql`
    query {
      headerweb: file(relativePath: { eq: "header-web3.jpg" }) {
        childImageSharp {
          fluid(maxWidth:1900, maxHeight:100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headertab: file(relativePath: { eq: "header-tab.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headercel: file(relativePath: { eq: "header-cel.jpg" }) {
        childImageSharp {
          fluid(maxWidth:500 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }     
      
    }`);      
    
    const sources = [
      //data.headerweb.childImageSharp.fluid,
      {
        ...data.headerweb.childImageSharp.fluid,
        media: '(min-width:1280px)',
        key:'headerweb'
      },
      //data.headertab.childImageSharp.fluid,
      {
        ...data.headertab.childImageSharp.fluid,
        media:'(min-width:480px)',
        key:'headertab'
      },
      //data.headercel.childImageSharp.fluid,
      {
        ...data.headercel.childImageSharp.fluid,
        media:'(max-width:480px)',
        key:'headercel'
      }
    ]
    
    const classes = useStyles()
    return (     
       
        <Container className={classes.titleContainer} maxWidth={false}>
         {/*} <Img fixed={data.logo.childImageSharp.fixed} alt="..." />
          <div style={{flexGrow:1}}></div>  
          <Img fixed={data.when.childImageSharp.fixed} alt="..." style={{marginRight:30}}/>
    {console.log(match)}*/}
          <Img loading="eager" fluid={sources} alt=".." imgStyle={{objectFit:'contain'}} className={classes.headerImg} />
        
        {/*<Typography component="h1" variant="h2" className={classes.headerTitle}>          
        
          <span className={classes.romanNumber}>&#8550;</span>          
          <span>Coloquio Uruguayo</span><br/>
          <span> de Matemática</span> 
        
        </Typography>
    
        <Typography component="h2" variant="h5" className={classes.when}>
            <b>18<span style={{color:'#f8b135'}}>,</span> 19 <span style={{color:'#f8b135'}}>y</span> 20 <span style={{color:'#f8b135'}}>dic</span></b> &#8213; 2019
    </Typography>*/}
          
     </Container>      
      
   
    
      
    )
}